import styled from "styled-components";

export const ColoredText = styled.p<{ color?: string }>`
  color: ${(props) => props.color || "var(--text-color)"};
`;

export const LineMaxedText = styled.p<{
  maxLine: number;
  color?: string;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.maxLine};
  line-clamp: ${(props) => props.maxLine};
  -webkit-box-orient: vertical;
`;

export const LineMaxedHeader = styled.h1<{
  maxLine: number;
  color?: string;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.maxLine};
  line-clamp: ${(props) => props.maxLine};
  -webkit-box-orient: vertical;
`;
