// import React from "react";
// import { CarouselWrapper } from "./carousell.styled";
import CarousellItemComponent from "./carousellItem.component";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

const MagazineCarousellComponent = ({ data }: { data: any }) => {
  return (
    <Swiper
      slidesPerView={2}
      slidesPerGroup={1}
      spaceBetween={250}
      loop={false}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
      breakpoints={{
        375: {
          slidesPerView: 1,
          spaceBetween: 10,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
          slidesPerGroup: 3,
        },
        1180: {
          slidesPerView: 4,
          spaceBetween: 50,
          slidesPerGroup: 3,
        },
        1450: {
          slidesPerView: 5,
          spaceBetween: 10,
          slidesPerGroup: 3,
        },
      }}
    >
      {data &&
        data.blocks &&
        data.blocks.map((block: any, index: number) => (
          <SwiperSlide key={index}>
            <CarousellItemComponent
              key={block.id + index}
              data={block}
              index={index}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default MagazineCarousellComponent;
