import React from "react";
import { Billboardanner, Skyscraper } from "./adSection.styled";

const AdSectionComponent = ({
  isVertical,
  src,
}: {
  isVertical?: boolean;
  src: string | null;
}) => {
  if (!src) {
    return <></>;
  }
  return (
    <>
      {!isVertical ? (
        <Billboardanner
          src={src}
          alt="Banner de publicidad"
          className="img-fluid"
        />
      ) : (
        <Skyscraper
          src={src}
          alt="Banner de publicidad"
          className="img-fluid"
        />
      )}
    </>
  );
};

export default AdSectionComponent;
