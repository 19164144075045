import React from 'react';
import AdSectionComponent from '../../Components/Sites/SiteSections/AdSection/adSection.component';
import BigGridSectionComponent from '../../Components/Sites/SiteSections/BigGridSection/bigGridSection.component';
import EditorialLeadSection from '../../Components/Sites/SiteSections/Editorial/editorialLeadSection.component';
import GridSectionComponent from '../../Components/Sites/SiteSections/GridSection/gridSection.component';
import HeroSection from '../../Components/Sites/SiteSections/HeroSection/heroSection.component';
import { useAppSelector } from '../../Utils/hooks/useAppDispatch.hook';
import { Loader } from '../../Components/Styled';
import MaintenencePage from '../Maintenece/maintenence.page';
import { Helmet } from 'react-helmet';
import GaleryComponent from '../../Components/Sites/SiteSections/Galery/galery.component';

const ForoCentroPage = () => {
  window.scrollTo(0, 0);

  const { siteData, siteError, siteLoading } = useAppSelector(
    (state) => state.site,
  );
  return (
    <div className=''>
      <Helmet>
        <title>Foro Centro</title>
        <link
          rel='canonical'
          href='http://nortenido.com/forocentromx'
        />
      </Helmet>
      {siteLoading && !siteError && (
        <Loader className=''>
          <div
            className='spinner-border text-primary spinner-border-sm'
            role='status'
          ></div>
          <span className='ms-1'>Cargando Contenido...</span>
        </Loader>
      )}
      {!siteLoading &&
        !siteError &&
        siteData &&
        !siteData.maintenanceMode && (
          <>
            <EditorialLeadSection data={siteData.heroSection} />
            <div className='content container-custom py-3 animated zoomInSmall faster'>
              <div className='mb-4'>
                <AdSectionComponent
                  src={siteData.primaryAdSection?.banner || null}
                />{' '}
              </div>
              <HeroSection
                data={siteData.secondarySection}
                spotifyUrl='https://open.spotify.com/embed/show/687qBS5Bdvl7LDEQY7vb26?'
              />
              <div className='mb-4'>
                <AdSectionComponent
                  src={siteData.tertiaryAdSection?.banner || null}
                />{' '}
              </div>

              <div className='header d-flex justify-content-between align-items-center mt-5 mb-3'>
                <h2 className='fw-bold'>
                  {siteData.tertiarySection.sectionTitle}
                </h2>
              </div>
              <div className='row h-100'>
                <div className='col-12 col-md-9'>
                  <GridSectionComponent
                    data={siteData.tertiarySection}
                  />
                </div>
                <div className='col-12 col-md-3 h-100 p-1'>
                  <div className='d-none d-md-block'>
                    <AdSectionComponent
                      isVertical
                      src={
                        siteData.secondaryAdSection?.skyscraper ||
                        null
                      }
                    />
                  </div>
                  <div className='d-block d-md-none'>
                    <AdSectionComponent
                      src={
                        siteData.secondaryAdSection?.banner || null
                      }
                    />{' '}
                  </div>
                </div>
              </div>
              <div className='header d-flex justify-content-between align-items-center mt-5 mb-3'>
                <h2 className='fw-bold'>
                  {siteData.quaternarySection?.sectionTitle}
                </h2>
              </div>
              <BigGridSectionComponent
                data={siteData.quaternarySection}
              />
              <div className='mb-4'>
                <AdSectionComponent
                  src={siteData.quaternaryAdSection?.banner || null}
                />{' '}
              </div>
              <div className='header d-flex justify-content-between align-items-center mt-5 mb-3'>
                <h2 className='fw-bold'>
                  {siteData.finalSection.sectionTitle}
                </h2>
              </div>
              <BigGridSectionComponent data={siteData.finalSection} />
              <div className='mt-3 mb-3 '>
                <GaleryComponent
                  data={siteData.galerySection}
                  disableDescription
                />
              </div>
            </div>
          </>
        )}
      {siteData && siteData.maintenanceMode && <MaintenencePage />}
    </div>
  );
};

export default ForoCentroPage;
