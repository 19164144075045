import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = ({ children }: any) => {
  return (
    <div className="h-100 w-100 d-flex flex-column container align-items-center justify-content-between  fadeIn animated faster overflow-hidden mt-5 pt-5">
      <div className="container ">
        <h1 className="fw-bold">
          Parece ser que no encontramos lo que buscas.
        </h1>
        <p className="text-muted">
          Puede ser que el link que buscas no exista o que haya sido eliminado.
          Intenta con otro link.
        </p>
        {children && <p className="small opacity-50">"{children}"</p>}
        <Link to="/" className="link-primary text-decoration-none fs-5">
          <p className="m-0">
            {" "}
            Inicio
            <i className="fa-solid fa-chevron-right ps-1" />
          </p>
        </Link>
      </div>
      <div></div>
    </div>
  );
};

export default NotFoundPage;
