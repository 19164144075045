import styled from "styled-components";

export const CarouselWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 2rem 0.1rem;
`;

export const Wrapper = styled.div`
  width: 290px;
  float: none;
  height: 100%;
  max-height: 400px;
  margin: 0 0.9%;
  display: inline-block;
  border-radius: 8px;
  animation: zoomInSmall 0.3s ease-in-out;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(0.99);
    box-shadow: 0 0 20px var(--primary-50);
  }
`;

export const Image = styled.img`
  object-fit: cover;
  border-radius: 8px;
  object-position: top center;
  height: 100%;
  max-height: 400px;
`;

export const ExpandedImage = styled.img`
  object-fit: cover;
  border-radius: 8px;
  object-position: top center;
  height: 100%;
  max-height: 700px;
`;
