import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import LayoutComponent from "./Components/Layout/layout.component";
import ArreMagazinePage from "./Pages/ArreMagazine/arreMagazine.component";
import ArticlePage from "./Pages/Article/article.page";
import ForoCentroPage from "./Pages/ForoCentro/foroCentro.page";
import HomePage from "./Pages/Home/home.page";
import NotFoundPage from "./Pages/NotFound/notFound.page";
import ProvacidadPage from "./Pages/Terms/privacidad.page";
import TheChingonesPage from "./Pages/TheChingones/theChingones.page";

import ReactGA from "react-ga";

const TRACKING_ID = "G-S2V523R1SS"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Router>
        <LayoutComponent>
          <Routes>
            <Route path="/" element={<Navigate to="/homesite" />} />
            <Route path="/homesite" element={<HomePage />} />
            <Route path="/forocentromx" element={<ForoCentroPage />} />
            <Route path="/thechingones" element={<TheChingonesPage />} />
            <Route path="/arremagazine" element={<ArreMagazinePage />} />
            <Route path="/:ownerId/:id" element={<ArticlePage />} />
            <Route path="/terminos" element={<ProvacidadPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </LayoutComponent>
      </Router>
    </>
  );
};

export default App;
