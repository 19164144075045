import styled from "styled-components";

export const IconImage = styled.img`
  border-radius: 15px;
  height: 150px;
  width: 150px;

  @media (max-width: 600px) {
    height: 100px;
    width: 100px;
  }
`;

export const Avatar = styled.img<{
  size?: string | null;
}>`
  width: ${({ size }) => size || "140px"};
  height: ${({ size }) => size || "140px"};
  background-color: var(--bg-color);
  border-radius: 100%;
  animation-duration: 0.3s;

  object-position: center;
`;

export const ContainedImage = styled.img<{}>`
  max-height: 467px;
  object-fit: contain;
  object-position: left;
  min-height: 467px;
  background-color: var(--bg-contrast-color);
  border-radius: var(--border-radius);
  animation: zoomInSmall 0.3s ease-in-out;
`;
