import React, { useRef } from "react";
import ReactDOM from "react-dom";
import useOutsideAlerter from "../../../Utils/hooks/useOutsideAlerter";

import { ModalContainer, ModalContent } from "./modal.styled";

const ModalWrapperComponent = ({
  children,
  onClose,
  display,
  limitSize,
}: {
  children: any;
  onClose: any;
  display: boolean;
  limitSize?: string;
}) => {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, onClose);

  return ReactDOM.createPortal(
    <ModalContainer>
      <ModalContent
        ref={wrapperRef}
        data-testid="portal-modal-content"
        limitSize={limitSize}
      >
        <button
          className="btn text-white border-0 btn-sm text-sm"
          onClick={onClose}
          data-testid="close-modal-btn"
          type="button"
        >
          <i className="fa-solid fa-circle-xmark" />
        </button>
        <div className="container-fluid mt-2">{children}</div>
      </ModalContent>
    </ModalContainer>,
    document.querySelector("#global-modal" as any)
  );
};

export default ModalWrapperComponent;
