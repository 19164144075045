import styled from "styled-components";

export const ArticleImage = styled.img`
  height: 100%;
  max-height: 650px;

  object-fit: cover;
  object-position: 0% 15%;
  border-radius: var(--border-radius);
`;

export const ArticleImageContainer = styled.div`
  position: relative;

  @media (max-width: 768px) {
    .previous {
      margin-top: 10px;
    }
  }

  @media (min-width: 769px) {
    .previous {
      position: absolute;
      width: 60%;
      bottom: -5%;
      right: 3%;
    }
  }

  @media (min-width: 991px) {
    .previous {
      position: absolute;
      width: 40%;
      bottom: -5%;
      right: 3%;
    }
  }
`;

export const ArticleContent = styled.div`
  line-height: 1.5;

  p {
    margin-bottom: 0;
    width: 100%;
  }

  p span {
    width: 100%;
    text-align: center;
  }

  img {
    width: 70%;
    border: none !important;
    max-height: 500px;
    height: auto;
    object-position: 0 15%;
    object-fit: cover;
    margin: 0 auto;
    border-radius: var(--border-radius);
    text-align: center;
  }
`;
