import { Timestamp } from 'firebase/firestore';
import { AdType } from '../Types/ads.t';
import { Publication } from '../Types/publication.t';
import { SiteType } from '../Types/site.t';
export const publicationConverter = {
  toFireStore: (publication: Publication) => {
    return {
      title: publication.title,
      indexing: publication.title.toLowerCase().split(' '),
      description: publication.description,
      location: publication.location,
      authorId: publication.authorId,
      author: publication.author,
      date: Timestamp.fromDate(new Date(publication.date) as Date),
      createdDate: publication.createdDate
        ? Timestamp.fromDate(
            new Date(publication.createdDate) as Date,
          )
        : Timestamp.now(),
      linkedPublication: publication.linkedPublication,
      updatedDate: Timestamp.now(),
      published: publication.published,
      publicationDate: publication.publicationDate,
      ownerId: publication.ownerId,
      tags: publication.tags,
      content: publication.content,
      image: publication.image ? publication.image : null,
      lastEditedBy: publication.lastEditedBy || null,
      quaternarySection: publication.quaternarySection || null,
    };
  },

  fromFireStore: (id: string, snapshot: any) => {
    return {
      id: id,
      title: snapshot.title,
      description: snapshot.description,
      authorId: snapshot.authorId,
      author: snapshot.author,
      linkedPublication: snapshot.linkedPublication,
      published: snapshot.published,
      ownerId: snapshot.ownerId,
      tags: snapshot.tags,
      content: snapshot.content,
      image: snapshot.image ? snapshot.image : null,
      location: snapshot.location,
      date: new Date(snapshot.date.seconds * 1000).toISOString(),
      createdDate: new Date(
        snapshot.createdDate.seconds * 1000,
      ).toISOString(),
      publicationDate: snapshot.publicationDate
        ? new Date(
            snapshot.publicationDate.seconds * 1000,
          ).toISOString()
        : null,
      updatedDate: new Date(
        snapshot.updatedDate.seconds * 1000,
      ).toISOString(),
      lastEditedBy: snapshot.lastEditedBy,
      quaternarySection: snapshot.quaternarySection,
    } as Publication;
  },
};

export const sitesConverter = {
  toFireStore: (site: SiteType) => {
    return {
      name: site.name,
      heroSection: site.heroSection,
      secondarySection: site.secondarySection,
      tertiarySection: site.tertiarySection,
      finalSection: site.finalSection,
      sections: site.sections,
      updatedDate: Timestamp.now(),
      maintenanceMode: site.maintenanceMode,
      primaryAdSection: site.primaryAdSection,
      secondaryAdSection: site.secondaryAdSection,
      carrouselSection: site.carrouselSection || null,
      galerySection: site.galerySection || null,
      quaternarySection: site.quaternarySection || null,
    };
  },

  fromFireStore: (id: string, snapshot: any) => {
    return {
      id: id,
      name: snapshot.name,
      heroSection: snapshot.heroSection,
      secondarySection: snapshot.secondarySection,
      tertiarySection: snapshot.tertiarySection,
      finalSection: snapshot.finalSection,
      sections: snapshot.sections,
      updatedDate: new Date(
        snapshot.updatedDate.seconds * 1000,
      ).toISOString(),
      maintenanceMode: snapshot.maintenanceMode,
      primaryAdSection: snapshot.primaryAdSection,
      secondaryAdSection: snapshot.secondaryAdSection,
      tertiaryAdSection: snapshot.tertiaryAdSection,
      quaternaryAdSection: snapshot.quaternaryAdSection,
      carrouselSection: snapshot.carrouselSection,
      galerySection: snapshot.galerySection,
      quaternarySection: snapshot.quaternarySection,
    } as SiteType;
  },
};

export const adConverter = {
  toFireStore: (ad: AdType) => {
    return {
      name: ad.name,
      destinationURL: ad.destinationURL,
      banner: ad.banner,
      skyscraper: ad.skyscraper,
      imageId: ad.imageId,
      createdDate: Timestamp.now(),
    };
  },

  fromFireStore: (id: string, snapshot: any) => {
    return {
      id: id,
      name: snapshot.name,
      destinationURL: snapshot.destinationURL,
      banner: snapshot.banner,
      skyscraper: snapshot.skyscraper,
      imageId: snapshot.imageId,
      createdDate: new Date(
        snapshot.createdDate.seconds * 1000,
      ).toISOString(),
    };
  },
};

export const termsConverter = {
  toFireStore: (item: any) => {
    return {
      content: item.content,
      updatedDate: Timestamp.now(),
      lastEditedBy: item.lastEditedBy || null,
    };
  },
  fromFireStore: (id: string, snapshot: any) => {
    return {
      id: id,
      content: snapshot.content,
      updatedDate: new Date(
        snapshot.updatedDate.seconds * 1000,
      ).toISOString(),
      lastEditedBy: snapshot.lastEditedBy,
    };
  },
};
