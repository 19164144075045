import styled from "styled-components";

export const HeaderImageWrapperPlaceholder = styled.div`
  width: 100%;
  height: 615px;
  background-color: var(--bg-contrast-color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
`;

export const HeaderImageWrapper = styled.div<{
  imgUrl?: string | null;
}>`
  width: 99%;
  height: 90vh;
  margin: auto;
  border-radius: var(--border-radius);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url(${(props) => props.imgUrl || ""});
  background-size: cover;
  background-color: var(--bg-color-contrast);
  background-position: 0% 15%;
  background-repeat: no-repeat;
  position: relative;

  .img-fluid {
    width: 12%;
    position: absolute;
    bottom: 8%;
    left: 45%;
  }

  .content {
    position: absolute;
    left: 5%;
    top: 25%;
    max-width: 350px;
  }

  .eyebrow {
    min-height: 50px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .eyebrow::before {
    content: " ";
    width: 3px;
    min-height: 40px;
    margin-right: 0.3rem;

    background: linear-gradient(180deg, #fdc40e 20.75%, #56bec2 100%);
    border-radius: 67px;
  }

  @media (min-width: 768px) {
    .content {
      max-width: 700px;
    }
  }

  @media (max-width: 600px) {
    .content {
      left: 3%;
      top: 35%;
      max-width: 100%;
    }
    .img-fluid {
      width: 33%;
      position: absolute;
      opacity: 0.5;
      bottom: 85%;
      left: 33%;
    }

    background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)),
      url(${(props) => props.imgUrl});
    background-size: cover;

    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
  }
`;
