import styled from "styled-components";

export const Video = styled.iframe`
  object-fit: cover;
  border-radius: var(--border-radius);
  object-position: top center;
  height: 100%;
  min-height: 733px;
  width: 100%;

  @media (max-width: 768px) {
    min-height: 400px;
  }

  @media (max-width: 576px) {
    min-height: 220px;
  }

  @media (max-width: 375px) {
    min-height: 200px;
  }
`;
