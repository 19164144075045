/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LineMaxedHeader, LineMaxedText } from "../../../Styled";
import { CarouselWrapper, Image } from "./galery.styled";

const GaleryComponent = ({
  data,
  disableDescription,
}: {
  data: any;
  disableDescription?: boolean;
}) => {
  const [image, setImage] = useState<number>(0);

  useEffect(() => {
    const second = setInterval(() => {
      if (image < data.blocks.length - 1) {
        setImage(image + 1);
      } else {
        setImage(0);
      }
    }, 10000);

    return () => clearInterval(second);
  }, [image]);

  return (
    data &&
    data.blocks &&
    data.blocks.length > 0 && (
      <div>
        <Image
          key={data.id}
          imgUrl={data.blocks[image].imageURL}
          disableDescription={disableDescription}
        >
          {!disableDescription && (
            <div className="content me-3 me-md-0">
              <div className="eyebrow">
                <p className="my-3 opacity-50  text-sm">
                  ¿Qué esta pasando <br /> Actualmente?
                </p>
              </div>
              <LineMaxedHeader
                maxLine={4}
                className="text-white title fs-3 fw-bold"
              >
                {data.blocks[image]?.title.split(":")[0]}
              </LineMaxedHeader>
              <LineMaxedText
                maxLine={2}
                className="text-white subTitle opacity-50"
              >
                {data.blocks[image]?.title.split(":")[1]}
              </LineMaxedText>
              <LineMaxedText
                maxLine={4}
                className="text-white description text-sm opacity-75"
              >
                {data.blocks[image]?.description}
              </LineMaxedText>
            </div>
          )}
        </Image>

        {data && data.blocks && data.blocks.length > 1 && (
          <CarouselWrapper className="">
            {data.blocks.map((block: any, index: number) => (
              <div
                className={`btn btn-transpartent border-none p-1 selectable ${
                  image === index ? "border-primary" : ""
                }`}
                onClick={() => setImage(index)}
              >
                <img
                  key={block.id}
                  src={block.imageURL}
                  className="img-thumbnail border-0"
                  alt={block.title}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
          </CarouselWrapper>
        )}
      </div>
    )
  );
};

export default GaleryComponent;
