import styled from "styled-components";

export const VerticalAdBanner = styled.div``;

export const Billboardanner = styled.img`
  max-height: 250px;
  min-height: 150px;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
`;

export const Skyscraper = styled.img`
  min-height: 600px;
  object-fit: contain;
  border-radius: 10px;
  object-position: top center;
  height: 100%;
`;
