import styled from "styled-components";

export const ContentContainer = styled.div`
  min-height: 95vh;
  width: 100%;
`;

export const TopBarWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
`;

export const SideBarMennu = styled.div<{
  show: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--bg-color);
  z-index: 999;
  transition: all 0.3s ease-in-out;
  transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};
  opacity: ${({ show }) => (show ? 1 : 0.5)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const MainNavigation = styled.nav`
  display: flex;
  flex-direction: row;
  transition: all 0.3s ease-in-out;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-body); ;
`;

export const BottomBarWrapper = styled.div`
  background-color: var(--bg-color-contrast);
  color: white;

  position: absolute;
  width: 100%;
`;
