import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import AdSectionComponent from '../../Components/Sites/SiteSections/AdSection/adSection.component';
import {
  findByQuery,
  getbyId,
} from '../../Redux/Actions/data.actions';
import { timeAgo } from '../../Utils/helpers';
import {
  useAppDispatch,
  useAppSelector,
} from '../../Utils/hooks/useAppDispatch.hook';
import {
  ArticleContent,
  ArticleImage,
  ArticleImageContainer,
} from './article.syled';
import HorizontalBoxComponent from '../../Components/Sites/SiteBlockComponent/HorizontalBox/horizontalBox.component';
import NotFoundPage from '../NotFound/notFound.page';
import { Loader } from '../../Components/Styled';
import { Helmet } from 'react-helmet';

import {
  LimitQuery,
  OrderByQuery,
  WhereQuery,
} from '../../Types/services.t';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import useAnalyticsEventTracker from '../../Utils/hooks/useAnalitycsTracker.hook';
import useScrollBlock from '../../Utils/hooks/useScrollBlock';

const ArticlePage = () => {
  window.scrollTo(0, 0);
  const { siteData } = useAppSelector((state) => state.site);
  const gaEventTracker = useAnalyticsEventTracker('ShareArticle');
  const { dataItem, error, loading, dataArray } = useAppSelector(
    (state) => state.data,
  );

  const { id } = useParams();

  const [blockScroll, allowScroll] = useScrollBlock();

  const dispatch = useAppDispatch();
  useEffect(() => {
    blockScroll();
    dispatch(
      getbyId({ collectionId: 'publications', id: id as string }),
    );

    const q1: LimitQuery = new LimitQuery(6);

    const q2: WhereQuery = new WhereQuery(
      'ownerId',
      '==',
      siteData?.id || 'homeSite',
    );
    const q3: OrderByQuery = new OrderByQuery('createdDate', 'desc');

    dispatch(
      findByQuery({
        collectionId: 'publications',
        queries: [q1, q2, q3],
      }),
    );
  }, [dispatch, id, siteData?.id]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        allowScroll();
      }, 1000);
    }
  }, [loading]);

  return (
    <div className=''>
      {loading && !error && (
        <Loader className=''>
          <div
            className='spinner-border text-primary spinner-border-sm'
            role='status'
          ></div>
          <span className='ms-1'>Cargando Contenido...</span>
        </Loader>
      )}
      {siteData && !error && (
        <div className='mb-4  container '>
          <AdSectionComponent
            src={siteData.primaryAdSection?.banner || null}
          />
        </div>
      )}
      {error && <NotFoundPage>{error}</NotFoundPage>}
      {dataItem && !loading && !error && (
        <div className='h-100 container px-md-4 animated fadeInUp fast'>
          <Helmet>
            {/* Standard metadata tags */}
            <meta name='description' content={dataItem.description} />
            <meta name='image' content={dataItem.image.fileUrl} />;
            {/* End standard metadata tags */}
            <meta itemProp='name' content={dataItem.title} />
            <meta
              itemProp='description'
              content={dataItem.description}
            />
            <meta itemProp='image' content={dataItem.image.fileUrl} />
            {/* Facebook tags */}
            <meta property='og:type' content='website' />
            <meta property='og:title' content={dataItem.title} />
            <meta
              property='og:description'
              content={dataItem.description}
            />
            <meta
              property='og:image'
              content={dataItem.image.fileUrl}
            />
            ;{/* End Facebook tags */}
            {/* Twitter tags */}
            <meta name='twitter:creator' content={dataItem.author} />
            <meta name='twitter:card' content='website' />
            <meta name='twitter:title' content={dataItem.title} />
            <meta
              name='twitter:description'
              content={dataItem.description}
            />
            {/* End Twitter tags */}
            <title>{dataItem.title}</title>
            <link
              rel='canonical'
              href={`http://nortenido.com/${dataItem.ownerId}/${id}`}
            />
          </Helmet>
          <div className='d-flex flex-wrap'>
            {dataItem.tags.map((tag: string, index: number) => (
              <span
                key={index}
                className='badge bg-primary me-1 mb-3 fw-normal p-2'
              >
                #{tag}
              </span>
            ))}
          </div>
          <h1 className='fw-bold'>{dataItem.title.split(':')[0]}</h1>
          <p className='lead opacity-75'>
            {' '}
            {dataItem.title.split(':')[1]}
          </p>

          <ArticleContent className='mt-4'>
            <p className='fs-5 fw-light my-3'>
              {' '}
              {dataItem.description}
            </p>{' '}
          </ArticleContent>
          <div className='d-flex justify-content-between align-items-center '>
            <p className=' opacity-75'>
              {timeAgo(dataItem.date)} &#8226; Por: {dataItem.author}
            </p>
          </div>
          <div className='d-flex justify-content-between align-items-center '>
            <p className=' opacity-25 small'>
              Actualizado:{timeAgo(dataItem.updatedDate)}
            </p>
          </div>

          <ArticleImageContainer className='image'>
            <ArticleImage
              src={dataItem.image.fileUrl}
              alt={dataItem.image.caption}
              className='img-fluid w-100'
            />
            {dataItem.linkedPublication && (
              <div className='previous'>
                <HorizontalBoxComponent
                  data={dataItem.linkedPublication}
                  className=''
                  eyebrow='También te puede interesar'
                />
              </div>
            )}
          </ArticleImageContainer>
          <div className='mt-3'>
            <p className='h5 my-2 fw-bold text-primary'>
              Comparte este articulo
            </p>
            <div className='d-flex gap-3'>
              <FacebookShareButton
                url={`http://nortenido.com/${dataItem.ownerId}/${id}`}
                quote={dataItem.title}
                className='Demo__some-network__share-button'
                onClick={() => gaEventTracker('facebook', 'share')}
              >
                <FacebookIcon size={32} round /> Facebook
              </FacebookShareButton>
              <TwitterShareButton
                title={dataItem.title}
                url={`http://nortenido.com/${dataItem.ownerId}/${id}`}
                className='Demo__some-network__share-button'
                onClick={() => gaEventTracker('twitter', 'share')}
              >
                <TwitterIcon size={32} round /> Twitter
              </TwitterShareButton>
              <WhatsappShareButton
                onClick={() => gaEventTracker('whatsapp', 'share')}
                title={dataItem.title}
                url={`http://nortenido.com/${dataItem.ownerId}/${id}`}
                className='Demo__some-network__share-button'
              >
                <WhatsappIcon size={32} round /> Whatsapp
              </WhatsappShareButton>
            </div>
          </div>
          <div className='row my-5'>
            <div className='col'>
              <ArticleContent className='editor fs-5 text-break'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataItem.content,
                  }}
                />
              </ArticleContent>
            </div>
            <div className='col-3 d-none d-md-block p-0'>
              {siteData && (
                <div className='mb-4 animated zoomInSmall fast container'>
                  <AdSectionComponent
                    isVertical
                    src={
                      siteData.secondaryAdSection?.skyscraper || null
                    }
                  />
                </div>
              )}
            </div>

            <div className='col-12  d-md-none p-0 mt-2'>
              {siteData && (
                <div className=' animated zoomInSmall fast container'>
                  <AdSectionComponent
                    src={siteData.secondaryAdSection?.banner || null}
                  />
                </div>
              )}
            </div>
            {siteData && !error && (
              <div className='my-4  container '>
                <AdSectionComponent
                  src={siteData.tertiaryAdSection?.banner || null}
                />
              </div>
            )}

            {dataArray && dataArray.length > 0 && (
              <div>
                <h2 className='fw-bold mt-5'>
                  Publicaciones Recientes
                </h2>
                <div className='row row-cols-1 row-cols-md-3'>
                  {dataArray.map((block: any, index: number) => (
                    <div
                      key={block.id + index}
                      className='col px-1 pt-2'
                    >
                      <HorizontalBoxComponent
                        data={{
                          imageURL: block.image.fileUrl || '',
                          id: block.id,
                          date: block.date,
                          author: block.author,
                          updatedDate: block.updatedDate,
                          ownerId: block.ownerId,
                          description: block.description,
                          title: block.title,
                        }}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
