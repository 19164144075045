import React from "react";
import { NavLink } from "react-router-dom";
import useScrollBlock from "../../Utils/hooks/useScrollBlock";
import { SideBarMennu } from "./layout.styled";

const MenuButtonComponent = ({
  setDisplayMenu,
  displayMenu,
  siteLogo,
}: {
  setDisplayMenu: any;
  displayMenu: any;
  siteLogo: any;
}) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const handleClose = () => {
    allowScroll();
    setDisplayMenu(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-body d-md-none me-2"
        onClick={() => {
          setDisplayMenu(!displayMenu);
          blockScroll();
        }}
      >
        <i className="fa-solid fa-bars" />
      </button>

      <SideBarMennu show={displayMenu} className="p-3">
        <button
          type="button"
          className="btn btn-sm btn-body d-md-none me-2"
          onClick={() => handleClose()}
        >
          <i className="fa-solid fa-circle-xmark fa-lg opacity-50" />
        </button>
        <div className="container">
          <img
            className="logo img-fluid mt-3"
            src={siteLogo}
            alt="logo"
            width="250"
          />
        </div>

        <div className="container mt-3">
          <NavLink
            className={(navData) =>
              navData.isActive
                ? "fw-bold nav-link  my-5 pt-3 fs-2"
                : "nav-link my-5 pt-3 fs-2"
            }
            to="/homesite"
            onClick={() => handleClose()}
          >
            Inicio
          </NavLink>

          <NavLink
            className={(navData) =>
              navData.isActive
                ? "fw-bold nav-link my-5 pt-3 fs-2"
                : "nav-link my-5 pt-3 fs-2"
            }
            to="/forocentromx"
            onClick={() => handleClose()}
          >
            Foro Centro{" "}
            <span className="text-xs text-primary fw-light ">(Política)</span>
          </NavLink>

          <NavLink
            className={(navData) =>
              navData.isActive
                ? "fw-bold nav-link my-5 pt-3 fs-2"
                : "nav-link my-5 pt-3 fs-2"
            }
            to="/thechingones"
            onClick={() => handleClose()}
          >
            The Chingones{" "}
            <span className="text-xs text-primary fw-light ">(Líderes)</span>
          </NavLink>

          <NavLink
            className={(navData) =>
              navData.isActive
                ? "fw-bold nav-link my-5 pt-3 fs-2"
                : "nav-link my-5 pt-3 fs-2"
            }
            to="/arremagazine"
            onClick={() => handleClose()}
          >
            Arre Magazine{" "}
            <span className="text-xs text-primary fw-light ">
              (Estilo de vida)
            </span>
          </NavLink>
        </div>
        <div className="footer">
          <p>© 2022</p>
          <p>Privacidad — Terminos y Condiciones</p>
          <div className="d-flex gap-3 my-5">
            <a
              className="text-dark"
              href="https://instagram.com/forocentro_mx?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-instagram fa-2xl" />
            </a>
            <a
              className="text-dark"
              href="https://open.spotify.com/show/2l9YjbummT7uJ4pcw4bW1Q?si=19fe82eae0934bf6"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-spotify fa-2xl" />
            </a>
          </div>
        </div>
      </SideBarMennu>
    </>
  );
};

export default MenuButtonComponent;
