import styled from "styled-components";

export const CustomFormInput = styled.input`
  &:focus,
  -webkit-autofill:focus {
    color: var(--text-color);
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--color-primary-shadow);
    background-color: var(--bg-contrast-color) !important;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-color);
    opacity: 0.2; /* Firefox */
  }
  background-color: var(--bg-contrast-color) !important;
  border-color: var(--bg-contrast-color);

  color: var(--text-color) !important;
`;

export const CustomFormInputInvisible = styled.input<{
  width?: string | null;
  background?: string | null;
  color?: string | null;
}>`
  &:focus,
  -webkit-autofill:focus {
    color: ${({ color }) => color || "var(--text-color)"};
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--color-dark);
  }

  &:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ color }) => color || "var(--text-color)"};
    opacity: 0.2; /* Firefox */
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ color }) => color || "var(--text-color)"};
    opacity: 0.4; /* Firefox */
    font-weight: bold;
  }
  background-color: transparent !important;
  border: none;
  border-radius: var(--border-radius);
  color: ${({ color }) => color || "var(--text-color)"};
  width: ${(props) => props.width || "auto"};
`;

export const CustomTextArea = styled.textarea`
  &:focus,
  -webkit-autofill:focus {
    color: var(--text-color);
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--color-primary-shadow);
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-color);
    opacity: 0.5; /* Firefox */
  }
  background-color: var(--color-gray);
  border-color: var(--color-gray);

  color: var(--text-color) !important;
`;

export const InputGroup = styled.div`
  /* border: 1px #c3cfd5; */
  border-radius: var(--border-radius);
  background-color: var(--bg-contrast-color);
`;

export const Input = styled.input`
  &:focus {
    border: none;
    background-color: transparent;
    color: var(--text-color);
  }
  border: none;
  padding: 0.5em 1em;
  background-color: transparent;
  width: 100%;
  caret-color: var(--text-color);
  color: var(--text-color);

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-color);
    opacity: 0.5; /* Firefox */
  }
`;

export const Icon = styled.span`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.2em 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CustomButton = styled.button<{
  color?: string | null;
  bgColor?: string | null;
  tooltip?: string | null;
}>`
  color: ${(props) => props.color || "var(--text-color)"};
  background-color: ${(props) => props.bgColor || "var(--color-dark)"};
  border-radius: var(--border-radius);
  border: none;
  transition: all 0.3s ease-in-out;
  padding-inline: 1.2rem;
  position: relative;
  width: max-content;
  height: min-content;

  &:focus,
  -webkit-autofill:focus {
    color: ${(props) => props.color || "var(--text-color)"};
    background-color: ${(props) => props.bgColor || "var(--color-dark)"};
    border-radius: var(--border-radius);
    border: none;
    transition: all 0.2s ease-in-out;
    padding-inline: 1.2rem;
    box-shadow: 0 0 0 0rem ${(props) => props.bgColor || "var(--color-dark)"};
  }

  :hover {
    cursor: pointer;
    color: ${(props) => props.color || "var(--text-color)"};
    background-color: ${(props) => props.bgColor || "var(--color-dark)"};
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
  }
  &::after {
    content: "${(props) => props.tooltip}";
    display: ${(props) => (props.tooltip ? "inline-block" : "none")};
    padding-left: 0.5rem;

    font-size: 12px;
    font-weight: bold;

    animation: zommInSmall 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 600px) {
    &::after {
      display: none;
    }
  }
`;
