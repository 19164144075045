import { createSlice } from "@reduxjs/toolkit";
import { SiteType } from "../../Types/site.t";
import { getSite } from "../Actions/site.actions";

type AuthStateState = {
  siteLoading: boolean;
  siteData: SiteType | null;
  siteError: string | null;
};
const initialState: AuthStateState = {
  siteLoading: false,
  siteData: null,
  siteError: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {},
  extraReducers: {
    // get By Id
    [getSite.pending.type]: (state) => {
      state.siteLoading = true;
      state.siteData = null;
      state.siteError = null;
    },
    [getSite.fulfilled.type]: (state, action) => {
      state.siteLoading = false;
      state.siteData = action.payload;
      state.siteError = null;
    },
    [getSite.rejected.type]: (state, action) => {
      state.siteLoading = false;
      state.siteError = action.payload;
      state.siteData = null;
    },
  },
});

export default siteSlice.reducer;
