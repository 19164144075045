import React from "react";
import { Link } from "react-router-dom";

const MaintenencePage = ({ children }: any) => {
  return (
    <>
      <div className="container  justify-content-between pt-5">
        <h1>
          <i className="fa-solid fa-screwdriver-wrench mb-3" />
        </h1>
        <h1 className="fw-bold"> Esta seccion esta en mantenimiento.</h1>
        <p className="text-muted">
          Intenta de nuevo mas tarde o visita otra seccion.
        </p>
        {children && <p className="small opacity-50">Error: "{children}"</p>}
        <Link to="/" className="link-primary text-decoration-none fs-5">
          <p className="m-0">
            {" "}
            Inicio
            <i className="fa-solid fa-chevron-right ps-1 fa-xs" />
          </p>
        </Link>
      </div>
    </>
  );
};

export default MaintenencePage;
