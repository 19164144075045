import React, { useEffect } from "react";
import { Loader } from "../../Components/Styled";
import { getTerms } from "../../Redux/Actions/data.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../Utils/hooks/useAppDispatch.hook";
import { ArticleContent } from "../Article/article.syled";

const ProvacidadPage = () => {
  window.scrollTo(0, 0);
  const dispatch = useAppDispatch();
  const { dataItem, loading, error } = useAppSelector((state) => state.data);

  useEffect(() => {
    dispatch(getTerms({ collectionId: "terms" }));
  }, [dispatch]);

  return (
    <div className="container my-3">
      {!loading && dataItem && (
        <ArticleContent className="editor fs-5 text-break">
          <div dangerouslySetInnerHTML={{ __html: dataItem.content }} />
        </ArticleContent>
      )}

      {loading && (
        <Loader className="">
          <div
            className="spinner-border text-primary spinner-border-sm"
            role="status"
          ></div>
          <span className="ms-1">Cargando Contenido...</span>
        </Loader>
      )}
    </div>
  );
};

export default ProvacidadPage;
