import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  endAt,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../../firebase/firebase";
import {
  publicationConverter,
  termsConverter,
} from "../../Services/firestoreDataConverter.service";
import { Publication } from "../../Types/publication.t";
import { mapErrorCodeToMessage } from "../../Utils/helpers";

export const onDeleteImage = (imageId: string, location: string) => {
  const desertRef = ref(storage, `${location}/${imageId}`);

  deleteObject(desertRef)
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};

export const getbyId = createAsyncThunk(
  "data/getbyId",
  async (
    { collectionId, id }: { collectionId: string; id: string },
    { rejectWithValue }
  ) => {
    try {
      const docRef = await getDoc(doc(db, collectionId, id));
      if (docRef.exists()) {
        return publicationConverter.fromFireStore(docRef.id, docRef.data());
      } else {
        return rejectWithValue("No existe");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAll = createAsyncThunk(
  "data/getAll",
  async (colectionId: string, { rejectWithValue }) => {
    const result: Publication[] = [];
    try {
      const q = query(
        collection(db, colectionId),
        orderBy("createdDate", "desc")
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        result.push(publicationConverter.fromFireStore(doc.id, data));
      });
      return result;
    } catch (error: any) {
      const errorCode = error.code;
      console.log(error);
      return rejectWithValue(mapErrorCodeToMessage(errorCode));
    }
  }
);

export const findByQuery = createAsyncThunk(
  "data/getByQuery",
  async (
    { collectionId, queries }: { collectionId: string; queries: any[] },
    { rejectWithValue }
  ) => {
    const queryConstraints = [] as any[];

    if (queries) {
      queries.forEach((query: any) => {
        switch (query.type) {
          case "orderBy":
            queryConstraints.push(
              orderBy(query.orderByField, query.orderDirection)
            );
            break;
          case "where":
            queryConstraints.push(
              where(query.field, query.condition, query.value)
            );
            break;
          case "limit":
            queryConstraints.push(limit(query.number));
            break;
          case "startAt":
            queryConstraints.push(startAt(query.value));
            break;
          case "endAt":
            queryConstraints.push(endAt(query.value));
            break;

          default:
            break;
        }
      });
    }
    const result: Publication[] = [];

    try {
      const q = query(collection(db, collectionId), ...queryConstraints);

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        result.push(publicationConverter.fromFireStore(doc.id, data));
      });
      return result;
    } catch (error: any) {
      const errorCode = error.code;
      console.log(error);
      return rejectWithValue(mapErrorCodeToMessage(errorCode));
    }
  }
);

export const findRecents = createAsyncThunk(
  "data/findRecents",
  async (collectionId: string, { rejectWithValue }) => {
    const result: Publication[] = [];
    try {
      const q = query(
        collection(db, collectionId),
        orderBy("updatedDate", "desc"),
        limit(20)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data: any = doc.data();

        result.push(publicationConverter.fromFireStore(doc.id, data));
      });

      return result;
    } catch (error: any) {
      const errorCode = error.code;
      console.log(error);
      return rejectWithValue(mapErrorCodeToMessage(errorCode));
    }
  }
);

export const getTerms = createAsyncThunk(
  "data/getTerms",
  async ({ collectionId }: { collectionId: string }, { rejectWithValue }) => {
    const result: any[] = [];
    try {
      const q = query(
        collection(db, collectionId),
        orderBy("updatedDate", "desc")
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        result.push(termsConverter.fromFireStore(doc.id, data));
      });
      return result[0];
    } catch (error: any) {
      const errorCode = error.code;
      console.log(error);
      return rejectWithValue(mapErrorCodeToMessage(errorCode));
    }
  }
);
