import React from 'react';
import { useNavigate } from 'react-router';
import { SiteblockType } from '../../../../Types/site.t';
import { getIcon, timeAgo } from '../../../../Utils/helpers';
import { LineMaxedHeader, LineMaxedText } from '../../../Styled';
import { Image, Wrapper } from './squareBigBox.styled';

const SquareBigBoxComponent = ({
  className,
  data,
}: {
  className?: string;
  data: SiteblockType | null;

  index: number;
}) => {
  const navigate = useNavigate();

  const [imageError, setImageError] = React.useState(false);

  const handleOnError = () => {
    setImageError(true);
  };
  return (
    <Wrapper
      className={className}
      onClick={() =>
        navigate(`/${data?.ownerId || 'homesite'}/${data?.id}`)
      }
    >
      {data && (
        <>
          <div className='d-flex p-3 align-items-center justify-content-between'>
            <div className='content pe-1'>
              <object
                style={{ height: '20px' }}
                data={getIcon(data.ownerId)}
                type='image/png'
              >
                <div></div>
              </object>
              <LineMaxedHeader maxLine={3} className='fs-5 fw-bold'>
                {data.title.split(':')[0]}
              </LineMaxedHeader>
              <LineMaxedText
                maxLine={2}
                className=' opacity-75 small'
              >
                {data.title.split(':')[1]}
              </LineMaxedText>
            </div>
            {!imageError && (
              <Image
                src={data.imageURL}
                className='img-fluid'
                onError={handleOnError}
              />
            )}
          </div>
          <div className='px-2'>
            <LineMaxedText
              maxLine={5}
              className='mb-0 opacity-50 text-break'
            >
              {data.description}
            </LineMaxedText>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2 px-2 pb-1 text-sm'>
            <small className='opacity-50'>
              {timeAgo(data.updatedDate)} &#8226; {data.author}
            </small>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default SquareBigBoxComponent;
