import React from 'react';
import { useNavigate } from 'react-router';
import { SiteblockType } from '../../../../Types/site.t';
import { getIcon, timeAgo } from '../../../../Utils/helpers';
import { LineMaxedHeader, LineMaxedText } from '../../../Styled';
import { Image, Wrapper } from './leadBigBox.styled';

const LeadBigBoxComponent = ({
  className,
  data,
  index,
}: {
  className?: string;
  data: SiteblockType | null;
  index: number;
}) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = React.useState(false);

  const handleOnError = () => {
    setImageError(true);
  };
  return (
    <Wrapper
      className={className}
      onClick={() =>
        navigate(`/${data?.ownerId || 'homesite'}/${data?.id}`)
      }
    >
      {data ? (
        <>
          <div className='w-100'>
            {!imageError && (
              <Image
                src={data.imageURL}
                className='img-fluid'
                onError={handleOnError}
              />
            )}
            <div className='content pt-4 px-3'>
              <object
                style={{ height: '20px' }}
                data={getIcon(data.ownerId)}
                type='image/png'
              >
                <div></div>
              </object>
              <LineMaxedHeader maxLine={3} className='fs-2 fw-bold'>
                {data.title.split(':')[0]}
              </LineMaxedHeader>
              <LineMaxedHeader
                maxLine={5}
                className='mb-0 fs-5 opacity-50 '
              >
                {data.title.split(':')[1]}
              </LineMaxedHeader>
              <LineMaxedText
                maxLine={7}
                className='mb-0 mt-4 opacity-50'
              >
                {data.description}
              </LineMaxedText>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2 px-3 pb-1 w-100'>
            <small className='opacity-50'>
              {timeAgo(data.updatedDate)} &#8226; {data.author}
            </small>
          </div>
        </>
      ) : (
        <>
          <div />
          <div className='text-center'>
            <i className='fa-solid fa-cube fa-3x text-muted mb-3' />
            <p className='mb-0 opacity-50'>No hay contenido</p>
          </div>

          <div />
        </>
      )}
    </Wrapper>
  );
};

export default LeadBigBoxComponent;
