import styled from "styled-components";

export const SelectableCard = styled.div`
  &:hover {
    background-color: var(--bg-contrast-color);
  }
  cursor: pointer;
  transition: all ease-out 0.3s;
  min-width: 300px;
`;
