import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { sites } from "../../constants/sites.c";
import { getSite } from "../../Redux/Actions/site.actions";
import { useAppDispatch } from "../../Utils/hooks/useAppDispatch.hook";
import FooterComponent from "./footer.component";
import { ContentContainer } from "./layout.styled";
import TopBarComponent from "./topBar.component";

function LayoutComponent({ children }: any) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentSite = sites.find(
    (s) => s.id === location.pathname.split("/")[1]
  );
  const [currentLogo, setCurrentLogo] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getSite(location.pathname.split("/")[1]));
    setCurrentLogo(currentSite?.logo);
  }, [currentSite, dispatch, location]);
  return (
    <>
      <TopBarComponent siteLogo={currentLogo} site={currentSite} />
      <ContentContainer data-theme="dark">{children}</ContentContainer>
      <FooterComponent siteLogo={currentLogo} />
    </>
  );
}

export default LayoutComponent;
