import { useState } from "react";
import ModalWrapperComponent from "../../../Common/Modals/modalWrapper.component";
import { ExpandedImage, Image, Wrapper } from "./carousell.styled";

const CarousellItemComponent = ({
  className,
  data,
  index,
}: {
  className?: string;
  data: any | null;
  index: number;
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Wrapper>
        {data && (
          <>
            {data.destinationURL ? (
              <a href={data.destinationURL} target="_blank" rel="noreferrer">
                <Image src={data.imageURL} className="img-fluid" />
              </a>
            ) : (
              <Image
                src={data.imageURL}
                className="img-fluid"
                onClick={() => setOpenModal(true)}
              />
            )}
          </>
        )}
      </Wrapper>
      {openModal && (
        <ModalWrapperComponent display={openModal} onClose={handleCloseModal}>
          <ExpandedImage
            src={data.imageURL}
            className="img-fluid"
            onClick={() => setOpenModal(true)}
          />
        </ModalWrapperComponent>
      )}
    </>
  );
};

export default CarousellItemComponent;
