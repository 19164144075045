import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: var(--bg-color-contrast);
  min-height: 705px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(0.99);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  object-position: top center;
`;
