import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { sitesConverter } from "../../Services/firestoreDataConverter.service";

export const getSite = createAsyncThunk(
  "site/getSite",
  async (id: string, { rejectWithValue }) => {
    try {
      const docRef = await getDoc(doc(db, "sites", id));
      if (docRef.exists()) {
        return sitesConverter.fromFireStore(docRef.id, docRef.data());
      } else {
        return rejectWithValue("No existe");
      }
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.error.message);
    }
  }
);
