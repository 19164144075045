import React from "react";
import LeadBigBoxComponent from "../../SiteBlockComponent/LeadBigBox/leadBigBox.component";
import VerticalBoxComponent from "../../SiteBlockComponent/VerticalBox/verticalBox.component";

const HeroSection = ({
  data,
  spotifyUrl,
  spotyfyUrl2,
}: {
  data: any;
  spotifyUrl: string;
  spotyfyUrl2?: string;
}) => {
  return (
    <>
      <div className="row">
        <div className="px-2 col-md-7">
          <LeadBigBoxComponent index={1} data={data?.primaryBlock} />
        </div>
        <div className="px-2 col-12 col-md-5">
          <div className="row">
            <div className="pe-1 pt-1 pt-md-0 px-md-1 col-6 ">
              <VerticalBoxComponent index={2} data={data?.secondaryBlock} />
            </div>
            <div className="ps-1 pt-1 px-md-1 pt-md-0 col-6">
              <VerticalBoxComponent data={data?.tertiaryBlock} index={3} />
            </div>
            <div className="px-md-1 col-12 pt-3 d-flex flex-column flex-md-row">
              <div className={`col-12 ${spotyfyUrl2 && "col-md-6"} p-0`}>
                <iframe
                  title="Horizontal Box on Spotify"
                  // src="https://open.spotify.com/embed/show/2l9YjbummT7uJ4pcw4bW1Q?"
                  src={spotifyUrl}
                  width="100%"
                  height="250"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
              </div>
              {spotyfyUrl2 && (
                <div className="col-12 col-md-6 ps-1 p-0">
                  <iframe
                    title="Horizontal Box on Spotify"
                    // src="https://open.spotify.com/embed/show/2l9YjbummT7uJ4pcw4bW1Q?"
                    src={spotyfyUrl2}
                    width="100%"
                    height="250"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
