import React from "react";
import VerticalBoxComponent from "../../SiteBlockComponent/VerticalBox/verticalBox.component";

const GridSectionComponent = ({ data }: { data: any }) => {
  return (
    <div className="row">
      <div className="col px-2">
        <div className="row row-cols-md-3 row-cols-lg-4 row-cols-2">
          {data &&
            data.blocks.map((block: any, index: number) => (
              <div key={block.id + index} className="col p-2">
                <VerticalBoxComponent data={block} index={index} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GridSectionComponent;
