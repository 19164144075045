import React from "react";
import { BottomBarWrapper } from "./layout.styled";
import icon from "../../Assets/logos/fc_logo_white.svg";
import { Link } from "react-router-dom";

const FooterComponent = ({ siteLogo }: { siteLogo: any }) => {
  return (
    <BottomBarWrapper>
      <div className="container-fluid pt-4 px-4 pb-2">
        <img
          className="logo img-fluid mt-3"
          src={siteLogo?.light ? siteLogo.light : icon}
          alt="logo"
          width="250"
        />
        <div className="footer  mt-4">
          <p className="mb-2">© 2022</p>
          <Link to="terminos" className="text-decoration-none text-primary">
            Terminos de Privacidad{" "}
          </Link>

          <div className="d-flex align-items-center justify-content-between opacity-50">
            <div>
              <p className=" opacity-50 mb-1">mxforocentro@gmail.com</p>
              <a href="tel:6271105286" className="text-white mb-2">
                (627) 110-5286
              </a>
            </div>
            <div className="d-flex gap-3">
              <a
                className="text-white"
                href="https://instagram.com/forocentro_mx?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram fa-lg" />
              </a>
              <a
                className="text-white"
                href="https://open.spotify.com/show/2l9YjbummT7uJ4pcw4bW1Q?si=19fe82eae0934bf6"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-spotify fa-lg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </BottomBarWrapper>
  );
};

export default FooterComponent;
