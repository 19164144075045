import React from 'react';
import { useNavigate } from 'react-router';
import { SiteblockType } from '../../../../Types/site.t';
import { getIcon, timeAgo } from '../../../../Utils/helpers';
import { LineMaxedHeader, LineMaxedText } from '../../../Styled';

import { Image, Wrapper } from './horizontalBox.styled';

const HorizontalBoxComponent = ({
  className,
  data,
  index,
  eyebrow,
}: {
  className?: string;
  data: SiteblockType | null;
  index?: number;
  eyebrow?: string;
}) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = React.useState(false);

  const handleOnError = () => {
    setImageError(true);
  };
  return (
    <Wrapper
      className={className}
      onClick={() =>
        navigate(`/${data?.ownerId || 'homesite'}/${data?.id}`)
      }
    >
      {data && (
        <>
          <div
            className={`d-flex p-3 ${
              data.description
                ? 'align-items-top'
                : 'align-items-center'
            } justify-content-between`}
          >
            <div className='content me-1'>
              <object
                style={{ height: '20px' }}
                data={getIcon(data.ownerId)}
                type='image/png'
              >
                <div></div>
              </object>

              {eyebrow && <p className='small'>{eyebrow}</p>}
              <LineMaxedHeader
                maxLine={3}
                className={` ${
                  data.description ? 'fs-5' : 'fs-4'
                }  fw-bold`}
              >
                {data.title.split(':')[0]}
              </LineMaxedHeader>
              <LineMaxedText
                maxLine={1}
                className='mb-0 opacity-50 small'
              >
                {data.title.split(':')[1]}
              </LineMaxedText>
              <LineMaxedText
                maxLine={3}
                className='mb-0 opacity-50 mt-3 text-break '
              >
                {data.description}
              </LineMaxedText>
            </div>

            {!imageError && (
              <Image
                src={data.imageURL}
                className='img-fluid'
                onError={handleOnError}
              />
            )}
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2 px-2 pb-1 text-sm'>
            <small className='opacity-50'>
              {timeAgo(data.date)} &#8226; {data.author}
            </small>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default HorizontalBoxComponent;
