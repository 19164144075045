// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'; // no compat for new SDK
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD60fX9FoNXNkQ79xUkxpMsoQKAV2S59l0',
  authDomain: 'nortenidomx.firebaseapp.com',
  projectId: 'nortenidomx',
  storageBucket: 'nortenidomx.appspot.com',
  messagingSenderId: '909806462337',
  appId: '1:909806462337:web:693bba3389f6b910deb480',
  measurementId: 'G-PH08N9813M',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
