import React from "react";
import { useNavigate } from "react-router";
import { timeAgo } from "../../../../Utils/helpers";
import { LineMaxedHeader, LineMaxedText } from "../../../Styled";
import { HeaderImageWrapper } from "./editorial.styled";

const EditorialLeadSection = ({ data }: { data: any }) => {
  const navigate = useNavigate();

  return (
    data?.primaryBlock && (
      <HeaderImageWrapper
        imgUrl={data?.primaryBlock.imageURL}
        className="animated zoomInSmall fast text-white"
      >
        {data.graphicUrl && (
          <img
            src={data?.graphicUrl}
            alt="logo"
            className="img-fluid animated zoomIn faster"
          />
        )}

        <div className="content me-3 me-md-0">
          <div className="eyebrow">
            <p className="my-3 opacity-50  text-sm">
              Historia <br /> Destacada
            </p>
          </div>
          <LineMaxedHeader
            maxLine={4}
            className="text-white title fs-3 fw-bold"
          >
            {data?.primaryBlock.title.split(":")[0]}
          </LineMaxedHeader>
          <LineMaxedText maxLine={2} className="text-white subTitle opacity-50">
            {data?.primaryBlock.title.split(":")[1]}
          </LineMaxedText>
          <LineMaxedText
            maxLine={8}
            className="text-white description text-sm opacity-75"
          >
            {data?.primaryBlock.description}
          </LineMaxedText>
          <div className="d-flex align-items-center gap-2 my-3 opacity-50">
            <small className="m-0">
              {timeAgo(data?.primaryBlock.updatedDate)}
            </small>
            -<small>Por {data?.primaryBlock.author}</small>
          </div>
          <button
            type="button"
            className="btn btn btn-outline-white btn-sm text-sm p-2"
            onClick={() =>
              navigate(
                `/${data?.primaryBlock.ownerId || "homesite"}/${
                  data?.primaryBlock.id
                }`
              )
            }
          >
            Seguir Leyendo <i className="fa-solid fa-arrow-right ms-1" />
          </button>
        </div>
      </HeaderImageWrapper>
    )
  );
};

export default EditorialLeadSection;
