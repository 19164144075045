import { sitesId } from "../Types/enums";

import homesiteLogoWhite from "../Assets/logos/nortenido_white.svg";
import homesiteLogoDark from "../Assets/logos/nortenido_color.svg";

import thechingonesLogoWhite from "../Assets/logos/thechingones_logo_white.svg";
import thechingonesLogoDark from "../Assets/logos/thechingones_logo_dark.svg";

import arremagazineLogoWhite from "../Assets/logos/arre_logo_white.svg";
import arremagazineLogoDark from "../Assets/logos/arre_logo_dark.svg";

import forocentromxLogoWhite from "../Assets/logos/fc_logo_white.svg";
import forocentromxLogoDark from "../Assets/logos/fc_logo_dark.svg";

export const sites: any[] = [
  {
    name: "Inicio",
    id: sitesId.homesite,
    logo: { light: homesiteLogoWhite, dark: homesiteLogoDark },
  },
  {
    name: "The Chingones",
    id: sitesId.theChingones,
    logo: { light: thechingonesLogoWhite, dark: thechingonesLogoDark },
  },
  {
    name: "Arre",
    id: sitesId.arre,
    logo: { light: arremagazineLogoWhite, dark: arremagazineLogoDark },
  },
  {
    name: "Foro Centro",
    id: sitesId.foroCentro,
    logo: { light: forocentromxLogoWhite, dark: forocentromxLogoDark },
  },
];
