import React from "react";
import HorizontalBoxComponent from "../../SiteBlockComponent/HorizontalBox/horizontalBox.component";

const RowSectionComponent = ({ data }: { data: any }) => {
  return (
    <>
      <div className="row row-cols-1 row-cols-md-3">
        {data &&
          data.blocks &&
          data.blocks.map((block: any, index: number) => (
            <div key={block.id + index} className="col px-1 pt-2">
              <HorizontalBoxComponent data={block} index={index} />
            </div>
          ))}
      </div>
    </>
  );
};

export default RowSectionComponent;
