import React from "react";
import AdSectionComponent from "../../Components/Sites/SiteSections/AdSection/adSection.component";
import BigGridSectionComponent from "../../Components/Sites/SiteSections/BigGridSection/bigGridSection.component";
import EditorialLeadSection from "../../Components/Sites/SiteSections/Editorial/editorialLeadSection.component";
import GridSectionComponent from "../../Components/Sites/SiteSections/GridSection/gridSection.component";
import MagazineCarousellComponent from "../../Components/Sites/SiteSections/MagazineCarousell/magazineCarousell";
import { useAppSelector } from "../../Utils/hooks/useAppDispatch.hook";

import MaintenencePage from "../Maintenece/maintenence.page";
import { Loader } from "../../Components/Styled";
import { Helmet } from "react-helmet";
import VideoCarousel from "../../Components/Sites/SiteSections/videoCarousel/VideoCarousel.component";
import GaleryComponent from "../../Components/Sites/SiteSections/Galery/galery.component";

const ArreMagazinePage = () => {
  window.scrollTo(0, 0);
  const { siteData, siteError, siteLoading } = useAppSelector(
    (state) => state.site
  );

  return (
    <>
      {siteLoading && !siteError && (
        <Loader className="">
          <div
            className="spinner-border text-primary spinner-border-sm"
            role="status"
          ></div>
          <span className="ms-1">Cargando Contenido...</span>
        </Loader>
      )}
      {!siteLoading && !siteError && siteData && !siteData.maintenanceMode && (
        <>
          <Helmet>
            <title>Arre Magazine</title>
            <link rel="canonical" href="http://nortenido.com/arremagazine" />
          </Helmet>
          <EditorialLeadSection data={siteData.heroSection} />
          <div className="content container-custom py-3 animated zoomInSmall faster">
            <div className="mb-4">
              <AdSectionComponent
                src={siteData.secondaryAdSection?.banner || null}
              />{" "}
            </div>
            <div className="header  justify-content-between align-items-center mt-5 mb-3">
              <h2 className="fw-bold">Ediciones Anteriores</h2>
              <p className="opacity-50">
                Encuentra todas nuestras ediciones en un solo lugar
              </p>
            </div>
            <MagazineCarousellComponent data={siteData.secondarySection} />
            <div className=" mt-5">
              <VideoCarousel data={siteData.carrouselSection} />
            </div>
            <div className="header d-flex justify-content-between align-items-center mt-5 mb-3">
              <h2 className="fw-bold">
                {siteData.tertiarySection.sectionTitle}
              </h2>
            </div>
            <div className="row h-100">
              <div className="col-12 col-md-auto">
                <GridSectionComponent data={siteData.tertiarySection} />
              </div>
              <div className="mt-3 mb-3">
                <GaleryComponent data={siteData.galerySection} />
              </div>

              {/* <div className="col-12 col-md-3 h-100 p-1">
                <div className="d-none d-md-block">
                  <AdSectionComponent
                    isVertical
                    src={siteData.secondaryAdSection?.skyscraper || null}
                  />
                </div>
                <div className="d-block d-md-none">
                  <AdSectionComponent src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbgAAAByCAMAAAAWEDTnAAAANlBMVEXg4ODy8/fv7+/y8/Xn6Ov09PTw8fPe39/k5OTv8/bg4eHp6end3uDe4N/l5ujt7vDj4uft7PEKY+pYAAADcUlEQVR4nO3d4ZKaMBRAYSEQMMHQvv/LNoirJAShbq5OnPPN9EelpatnIBCBnk4AAAAAAAAAAAAAAODLXC6XT/8IeIUhXH59I8869+m3+X3qSpxSSn/6bX6fN4XrPv0+vw7hCkW4QhGuUIQrFOEKRbhC3cOpWgDhxNzDNfnnpS5GE07KIpzJvW5jFOGkPMJ12cNdCCfnJ9y5EVi5UWfCCSFcoQhXqDzhBjMZ4lGScHKyhDN29HFGF+chnJwM4bq+VrPRhIemhJOTIVxft1M17X/V4RLCyfl9OON3k+q+lqAQ4eT8Plz/6Fadw4tLCCcnT7j7Fqf65SLCySFcoQhXqKfh7JE1BGOcChYRTs6zcLY9VC44qgyWEE7Ok3DGn5nZA9/19KrVt3Y6/OOEk7Mdbmimwas58JHPMydTtz5cQDg52+HMPI3lut0Pvev93rLS2kbdCCdoO5y9TUDaAx+6cc7167upCCdnK9zw57bAb3Orr2uSEtcaEU7OZri/93Dq5bvbCCdnK5yrHuFUPHYdRTg5G+GGsQrLxXtLe+QUj3ByNsK5xXSI/+i1G8K/5s/xDsyREU7ORrgxDOfLBYu76ZDTb3M7PQgnJx3OBt38FqeCOZGun+ZK2npv7COcnFQ4Y3S17Hb9jTb3vaXpxukl32OnHOHkpMINzblaUXoYTvMsysWp23a4c7xJODnJLe6cCqfGn72lPzL5Odx8/v0B4eSkwtl0ODXeDkZs+wj3tBzh5CTChacCS+M8zkWvDsOwsXLCyUmEGzfDqXGatozvPh4N4d5vHW55DUlib3nq2+hVrVfXnt8QTs4q3HSov7XB+QXjqY7DTafn6bsiCSdnFc4lDkyCvWUqq3LTOLeqQzg5cTijd8Kl96N+9Eu0IZycOJxVr4Sb79RZrZxwcqJw/txaPw1XbWxxfvQzq6NLwsmJwm2fw+3SynFH6vtE4ZrXw12vCAtXTjg5+ba4xBVhhJMThbOvh7smaoJhjnBysoarrlMrD4STkzmcVvXiCIVwchLhfqdtH1+uEk5OfALeZ3BfOeHk8EioQhGuUJLPqzQ8IVaObDgeNCpmEY5nMpdE9inobHFi+H8HCkW4QhGuUIQrFOEKRbhCjfsffIZyhMuubt+h3v9B8H+yz3N97B8BMGOAAwAAAAAAAAAAAAAAOOQf6ykt3Rg1doEAAAAASUVORK5CYII=" />
                </div>
              </div> */}
            </div>

            <div className="header d-flex justify-content-between align-items-center mt-5 mb-3">
              <h2 className="fw-bold">{siteData.finalSection.sectionTitle}</h2>
            </div>
            <BigGridSectionComponent data={siteData.finalSection} />

            <div className=" mt-4">
              <AdSectionComponent
                src={siteData.secondaryAdSection?.banner || null}
              />
            </div>
          </div>
        </>
      )}

      {siteData && siteData.maintenanceMode && <MaintenencePage />}
    </>
  );
};

export default ArreMagazinePage;
