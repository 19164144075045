/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Video } from "./video.styled";

const VideoCarousel = ({ data }: { data: any }) => {
  const [video, setVideo] = useState<number>(0);

  return (
    data &&
    data.blocks &&
    data.blocks.length > 0 && (
      <div>
        <Video
          key={data.sectionId}
          src={`https://www.youtube.com/embed/${
            data.blocks[video]?.destinationURL || ""
          }`}
        ></Video>
        {data && data.blocks && data.blocks.length > 1 && (
          <div className="d-flex justify-content-center align-items-center mt-2">
            {data.blocks.map((block: any, index: number) => (
              <div
                className={`btn btn-transpartent border-none p-1 selectable ${
                  video === index ? "text-primary" : ""
                }`}
                onClick={() => setVideo(index)}
              >
                <i className="fa-solid fa-circle text-xs" />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );
};

export default VideoCarousel;
