import styled from "styled-components";

export const HomeIcon = styled.div`
  border-radius: 15px;
  height: 80px;
  width: 80px;
  background-color: var(--color-lead);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--bg-contrast-color-dark);
  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.1);
    background-color: var(--bg-contrast-color);
  }
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 40%;
`;
