import styled from "styled-components";

export const Image = styled.div<{
  imgUrl?: string | null;
  disableDescription?: boolean;
}>`
  width: 100%;
  height: 70vh;
  margin: auto;
  border-radius: var(--border-radius);
  background: linear-gradient(
      0deg,
      ${(props) =>
        props.disableDescription ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.8)"},
      rgba(0, 0, 0, 0)
    ),
    url(${(props) => props.imgUrl || ""});
  background-size: contain;
  background-color: var(--bg-color);
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;

  .img-fluid {
    width: 12%;
    position: absolute;
    bottom: 8%;
    left: 45%;
  }

  .content {
    position: absolute;
    left: 5%;
    bottom: 20%;
    max-width: 350px;
  }

  .eyebrow {
    min-height: 50px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .eyebrow::before {
    content: " ";
    width: 3px;
    min-height: 40px;
    margin-right: 0.3rem;

    background: linear-gradient(180deg, #fdc40e 20.75%, #56bec2 100%);
    border-radius: 67px;
  }

  @media (min-width: 768px) {
    .content {
      max-width: 700px;
    }
  }

  @media (max-width: 600px) {
    .content {
      position: absolute;
      left: 5%;
      bottom: 5%;
      max-width: 350px;
    }
    .img-fluid {
      width: 100%;
      position: absolute;
      opacity: 0.5;
      bottom: 85%;
      left: 33%;
    }

    background-size: contain;
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 0.1rem;
`;
