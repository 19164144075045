import React, { useRef, useState } from "react";
import { TopBarWrapper, MainNavigation } from "./layout.styled";
import icon from "../../Assets/logos/nortenido_white.svg";
import { Link, NavLink } from "react-router-dom";
import MenuButtonComponent from "./menuButton-component";

const TopBarComponent = ({
  siteLogo,
  logoNegative,
  site,
}: {
  siteLogo: any;
  logoNegative?: string;
  site?: any;
}) => {
  const wrapperRef = useRef<any>(null);
  const iconRef = useRef<any>(null);

  const [displayMenu, setDisplayMenu] = useState(false);
  return (
    <MainNavigation
      className={`container-fluid sticky-top bg-body`}
      ref={wrapperRef}
    >
      <TopBarWrapper className=" animated fast pt-1" ref={iconRef}>
        <MenuButtonComponent
          displayMenu={displayMenu}
          setDisplayMenu={setDisplayMenu}
          siteLogo={siteLogo?.light ? siteLogo.light : icon}
        />

        <Link to={`/${site ? site?.id : "homesite"}`}>
          <img
            className="logo"
            src={siteLogo?.light ? siteLogo.light : icon}
            alt="logo"
            width="200"
            height="50"
          />
        </Link>
      </TopBarWrapper>

      <div
        className={`justify-content-center align-items-center gap-5 d-none d-md-flex  ms-4`}
      >
        <NavLink
          className={(navData) =>
            navData.isActive ? "fw-bold nav-link indicator-active" : "nav-link"
          }
          to="/homesite"
        >
          Inicio
        </NavLink>

        <NavLink
          className={(navData) =>
            navData.isActive ? "fw-bold nav-link indicator-active" : "nav-link "
          }
          to="/forocentromx"
        >
          Foro Centro{" "}
          <span className="text-xs text-primary fw-light d-lg-inline d-none">
            (Política)
          </span>
        </NavLink>

        <NavLink
          className={(navData) =>
            navData.isActive ? "fw-bold nav-link indicator-active" : "nav-link"
          }
          to="/thechingones"
        >
          {" "}
          The Chingones{" "}
          <span className="text-xs text-primary fw-light d-lg-inline d-none">
            (Líderes)
          </span>
        </NavLink>

        <NavLink
          className={(navData) =>
            navData.isActive ? "fw-bold nav-link indicator-active" : "nav-link"
          }
          to="/arremagazine"
        >
          Arre Magazine{" "}
          <span className="text-xs text-primary fw-light d-lg-inline d-none">
            (Estilo de vida)
          </span>
        </NavLink>
      </div>
      <div style={{ width: "200px" }} />
    </MainNavigation>
  );
};

export default TopBarComponent;
