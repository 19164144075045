import { createSlice } from "@reduxjs/toolkit";
import {
  findByQuery,
  findRecents,
  getAll,
  getbyId,
  getTerms,
} from "../Actions/data.actions";

type AuthStateState = {
  loading: boolean;
  searchLoading: boolean;
  dataArray: any[];
  searchResults: any[];
  dataItem: any | null;
  error: string | null;
};
const initialState: AuthStateState = {
  loading: false,
  searchLoading: false,
  dataArray: [],
  searchResults: [],
  dataItem: null,
  error: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    filterData: (state, action) => {
      state.dataArray = state.dataArray.filter((item) =>
        item.title.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
  },
  extraReducers: {
    //get by id
    [getbyId.pending.type]: (state) => {
      state.loading = true;
      state.dataItem = null;
      state.error = null;
    },
    [getbyId.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.dataItem = action.payload;
    },
    [getbyId.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.dataItem = null;
    },
    // Data get all documents
    [getAll.pending as any]: (state) => {
      state.loading = true;
      state.error = null;
      state.dataArray = [];
    },
    [getAll.fulfilled as any]: (state, { payload }) => {
      state.loading = false;
      state.dataArray = payload;
    },
    [getAll.rejected as any]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.dataArray = [];
    },

    // Data recents documents
    [findRecents.pending as any]: (state) => {
      state.loading = true;
      state.error = null;
      state.dataArray = [];
    },
    [findRecents.fulfilled as any]: (state, { payload }) => {
      state.loading = false;
      state.dataArray = payload;
    },
    [findRecents.rejected as any]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.dataArray = [];
    },

    // Data documents by query
    [findByQuery.pending as any]: (state) => {
      state.loading = true;
      state.error = null;
      state.dataArray = [];
    },
    [findByQuery.fulfilled as any]: (state, { payload }) => {
      state.loading = false;
      state.dataArray = payload;
    },
    [findByQuery.rejected as any]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.dataArray = [];
    },
    [getTerms.pending as any]: (state) => {
      state.loading = true;
      state.error = null;
      state.dataItem = null;
    },
    [getTerms.fulfilled as any]: (state, { payload }) => {
      state.loading = false;
      state.dataItem = payload;
    },
    [getTerms.rejected as any]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.dataItem = null;
    },
  },
});

export const { filterData } = dataSlice.actions;
export default dataSlice.reducer;
