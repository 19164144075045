import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: var(--bg-color-contrast);
  height: 275px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(0.99);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 767px) {
    height: max-content;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 40%;
  object-fit: cover;
  border-radius: 8px;
  object-position: top center;
  margin-left: 4px;

  @media (max-width: 768px) {
    width: 30%;
  }
  @media (min-width: 1076px) {
    height: 150px;
    width: 150px;
  }
`;
